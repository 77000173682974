<template>
  <v-app>
    <v-main id="main">
      <v-container fluid>
        <v-row justify="center" align-content="center" style="height: 500px">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12 rounded-0" :loading="loading">
              <div class="pa-8">
                <span style="image-rendering: -webkit-optimize-contrast"
                  ><v-img :src="require('../../assets/delphoi_top.png')" width="150"></v-img>
                </span>
                <div v-if="step === 'reissue-request'">
                  <v-card-title class="h6 font-weight-black">パスワード再発行依頼</v-card-title>
                  <v-card-text class="pb-0">登録済みのログインIDとメールアドレスを入力してください。</v-card-text>
                  <v-card-text class="red--text pb-0" v-show="error">{{ error }}</v-card-text>
                  <v-card-text>
                    <v-form ref="reissueRequestForm" v-model="validReissueRequestForm" lazy-validation>
                      <v-text-field
                        autocomplete="username"
                        label="ログインID"
                        v-model="loginId"
                        prepend-icon="mdi-account-circle"
                        type="text"
                        :rules="[rules.required, rules.maxLength(128)]"
                      />
                      <v-text-field
                        autocomplete="email"
                        label="メールアドレス"
                        v-model="email"
                        prepend-icon="mdi-email"
                        type="mail"
                        :rules="[rules.required, rules.maxLength(256), rules.mailAddress]"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="rounded-0" color="secondary" :to="{ name: 'Login' }">戻る</v-btn>
                    <v-spacer />
                    <v-btn class="rounded-0" color="primary" @click="reissueRequest">次へ</v-btn>
                  </v-card-actions>
                </div>
                <div v-if="step === 'check-code'">
                  <v-card-title class="h6 font-weight-black">本人確認</v-card-title>
                  <v-card-text class="pb-0"
                    >{{ email }} にコードを送信しました。<br />
                    届いたメールを確認して、認証コードをここに入力してください。
                  </v-card-text>
                  <v-card-text class="red--text pb-0" v-show="error">{{ error }}</v-card-text>
                  <v-card-text>
                    <v-form ref="authenticationCodeForm" v-model="validAuthenticationCodeForm" lazy-validation>
                      <v-text-field
                        label="認証CD"
                        v-model="authentication_code"
                        prepend-icon="mdi-shield-key"
                        type="text"
                        :rules="[rules.required, rules.maxLength(6)]"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="rounded-0" color="secondary" :to="{ name: 'Login' }">キャンセル</v-btn>
                    <v-spacer />
                    <v-btn class="rounded-0" color="primary" @click="checkAuthenticationCode">次へ</v-btn>
                  </v-card-actions>
                </div>
                <div v-if="step === 'input-password'">
                  <v-card-title class="h6 font-weight-black">パスワードのリセット</v-card-title>
                  <v-card-text class="pb-0">8 文字以上、大文字と小文字の区別があります</v-card-text>
                  <v-card-text class="red--text pb-0" v-show="error">{{ error }}</v-card-text>
                  <v-card-text>
                    <v-form ref="changePasswordForm" v-model="validChangePasswordForm" lazy-validation>
                      <v-text-field
                        :type="show_password ? 'text' : 'password'"
                        :append-icon="show_password ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="show_password = !show_password"
                        autocomplete="new-password"
                        label="新しいパスワード"
                        v-model="new_password"
                        :rules="[rules.required, rules.password]"
                      />
                      <v-text-field
                        :type="show_password ? 'text' : 'password'"
                        autocomplete="new-password"
                        label="パスワードの再入力"
                        v-model="new_password_again"
                        :rules="[rules.required, rules.password2]"
                      />
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn class="rounded-0" color="secondary" :to="{ name: 'Login' }">キャンセル</v-btn>
                    <v-spacer />
                    <v-btn class="rounded-0" color="primary" @click="changePassword">次へ</v-btn>
                  </v-card-actions>
                </div>
                <div v-if="step === 'request-complete'">
                  <v-card-title class="h6 font-weight-black">セキュリティ情報の更新完了</v-card-title>
                  <v-card-text>パスワードが変更されました</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn class="rounded-0" color="primary" :to="{ name: 'Login' }">ログイン</v-btn>
                  </v-card-actions>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
#main {
  background: linear-gradient(300deg, #ffd244, #f9c2c2);
}
</style>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "ResetPassword",
  data() {
    return {
      step: "reissue-request",
      loginId: "",
      email: "",
      authentication_code: "",
      new_password: "",
      new_password_again: "",
      show_password: false,
      error: "",
      loading: false,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        password: Validation.password,
        password2: (value) => this.new_password === value || "パスワードが一致しません",
        mailAddress: Validation.mailAddress,
      },
      validReissueRequestForm: null,
      validAuthenticationCodeForm: null,
      validChangePasswordForm: null,
    };
  },
  methods: {
    async reissueRequest() {
      try {
        this.error = "";
        this.loading = true;
        this.loadingOn();
        if (this.validateReissueRequest()) {
          const response = await this.$store.dispatch("password/reissueRequest", {
            loginId: this.loginId,
            mailAddress: this.email,
          });
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          this.step = "check-code";
        } else {
          this.error = "有効なログインIDとメールアドレスを入力してください";
        }
      } catch (error) {
        console.error("ResetPassword::reissueRequest", error);
        this.apiRequestError(error);
      } finally {
        // ここでリセットしておかないと次画面にステップしたときにバリデーションエラーが発生
        this.$refs.reissueRequestForm.resetValidation();
        this.loading = false;
        this.loadingOff();
      }
    },
    async checkAuthenticationCode() {
      try {
        this.error = "";
        this.loading = true;
        this.loadingOn();
        if (this.validateAuthenticationCode()) {
          const response = await this.$store.dispatch("password/checkAuthenticationCode", {
            loginId: this.loginId,
            authorizationcode: this.authentication_code,
          });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.step = "input-password";
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.error = "認証コードに誤りがあります";
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.error = "認証コードに誤りがあります";
        }
      } catch (error) {
        console.error("ResetPassword::checkAuthenticationCode", error);
        this.apiRequestError(error);
      } finally {
        // ここでリセットしておかないと次画面にステップしたときにバリデーションエラーが発生
        this.$refs.authenticationCodeForm.resetValidation();
        //this.$refs.changePasswordForm.resetValidation();
        this.loading = false;
        this.loadingOff();
      }
    },
    async changePassword() {
      try {
        this.error = "";
        this.loading = true;
        this.loadingOn();
        if (this.validateChangePassword()) {
          const response = await this.$store.dispatch("password/reissuePassword", {
            loginId: this.loginId,
            oldPassword: "",
            newPassword: this.new_password,
            authenticationCd: this.authentication_code,
          });
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info(`パスワードが変更されました`, { timeout: 2300 });
              this.step = "request-complete";
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              this.error = "入力されたパスワードに誤りがあります";
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.error = "入力されたパスワードに誤りがあります";
        }
      } catch (error) {
        console.error("ResetPassword::changePassword", error);
        this.apiRequestError(error);
      } finally {
        this.loading = false;
        this.loadingOff();
      }
    },
    validateReissueRequest() {
      const isValid = this.$refs.reissueRequestForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.reissueRequestForm.resetValidation();
      }
      return isValid;
    },
    validateAuthenticationCode() {
      const isValid = this.$refs.authenticationCodeForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.authenticationCodeForm.resetValidation();
      }
      return isValid;
    },
    validateChangePassword() {
      const isValid = this.$refs.changePasswordForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.changePasswordForm.resetValidation();
      }
      return isValid;
    },
  },
};
</script>
